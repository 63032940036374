@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
body {
  margin: 0;
  font-family: "Figtree", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 50;
  padding-right: 2.5rem;
}

.custom-tooltip:before {
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translate(-50%);
}

.react-datepicker__input-container {
  border: 1px solid #e4e4e7;
  padding: 6px;
  background-color: white;
  border-radius: 4px;
  height: 40px;
  margin-top: 9px;
}
.react-datepicker__input-container input {
  background-color: white;
  margin-left: 20px;
  outline: none;
  cursor: pointer;
  caret-color: transparent;
}
.react-datepicker-ignore-onclickoutside {
  background-color: white;
  border: none;
}
.react-datepicker-ignore-onclickoutside:focus-visible {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
