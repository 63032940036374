.custom-paginate {
    display: flex;
    flex-wrap: wrap;
  }
  
  .custom-paginate li {
    background: white;
    width: auto;
    color: #636e72;
    border: 1px solid #e9e9e9;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-paginate .selected {
    color: #5d5fef;
    font-weight: 600;
  }
  
  .custom-paginate .previous.disabled {
    opacity: 0.5;
  }
  .custom-paginate .next.disabled {
    opacity: 0.5;
  }
  
  .custom-paginate span {
    display: flex;
    align-items: center;
    line-height: 17px;
  }
  
  .custom-paginate li.previous {
    margin-left: 0 !important;
  }
  
  .custom-paginate li.previous:hover {
    color: #5d5fef;
    transition: 0.2s;
  }
  
  .custom-paginate li.next {
    margin-right: 0 !important;
  }
  
  .custom-paginate li.next:hover {
    color: #5d5fef;
    transition: 0.2s;
  }
  
  .custom-paginate .previous.disabled:hover {
    color: #636e72;
  }
  .custom-paginate .next.disabled:hover {
    color: #636e72;
  }
  